.infoToolTip {
  position: relative;
  height: max-content;
  //height: 30px;
  width: 100%;
  background-color: #9ec39e;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0 3px 10px 0 #504e4e;
  padding: 10px;

  &_animation {
    animation: smoothAppearance 5s forwards;
  }

  &_error {
    background-color: #ff9696;
    //  height: max-content;
    //height: 50px;
  }

  &__text {
    //position: absolute;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #395e39;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //line-height: 15px;
    &_error {
      color: #613030;
    }
  }
}

@keyframes smoothAppearance {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
