.shareArticle {
  &__link {
    display: block;
  }

  &__pinterest {
    display: block;
    object-fit: contain;
  }
}
