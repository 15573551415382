@import '../../styles/vars';
$colorBackgroundLinkHover: rgba(255, 150, 150, 0.6);
$colorBackgroundButton: #ff9696;
$ColorTextButton: white;

.photoGallery {
  width: 100%;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  justify-items: center;

  &__photos-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-rows: repeat(3, 333px);
    grid-template-columns: repeat(4, 333px);
    grid-auto-rows: 333px;
    grid-gap: 36px;
  }

  &__title {
    margin: 30px auto 0;
    font-size: 24px;
    font-weight: 400;
    color: $TextColorBrownDark;
  }

  &__photo-wrapper {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
  }

  &__photo {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .photoGallery {
    &__title {
      font-size: 20px;
    }
  }
}
