@import "../../styles/vars";

.admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__formButton {
    margin-top: 30px;
  }

  &__formFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 15px 0;
  }

  &__formField {
    margin-top: 5px;
    max-width: 100%;
    width: 100%;
    padding: 10px 0 10px 10px;
    border: 1px solid rgba(128, 128, 128, 0.4);
    border-radius: 5px;
  }

  &__formLabel {
    color: $TextColorBrownDark;
  }

  &__formCheckbox {
    margin-left: 10px;
  }

  &__formContainerCheckbox {
    display: flex;
    align-items: center;
  }

  &__errorFormField {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
}
