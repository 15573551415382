$colorBackground: rgba(0, 0, 0, 0.4);
$colorBackgroundScroll: rgba(0, 0, 0, 0.8);
$colorIconBurgerMenu: rgba(255, 252, 236, 1);

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  margin: 0 auto;
  background-color: $colorBackground;
  z-index: 20;
  transition: all 1s;

  &__changeColor {
    background-color: $colorBackgroundScroll;
  }

  &__logo {
    width: 200px;
    margin: auto 50px;
    z-index: 1;

    &:nth-of-type(2) {
      margin-bottom: 7px;
      margin-left: 20px;
    }
  }

  &__container {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
  }

  &__burger-icon {
    display: none;
    visibility: hidden;
  }

  &__buttonChangeLanguage {

  }
}

@media screen and (max-width: 1200px) {
  .header {
    &__logo {
      margin-left: 30px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .header {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__burger-icon {
      z-index: 1;
      display: block;
      visibility: visible;
      height: 30px;
      width: 30px;
      position: relative;
      margin-right: 30px;

      &:hover {
        cursor: pointer;
      }

      & span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1);
        transition: all 0.3s ease-in;
        left: 0;
        height: 10%;
        width: 100%;
        background-color: $colorIconBurgerMenu;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 10%;
        width: 100%;
        background-color: $colorIconBurgerMenu;
        transition: all 0.3s ease-in;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 10%;
        width: 100%;
        background-color: $colorIconBurgerMenu;
        transition: all 0.3s ease-in;
      }

      &_close {
        & span {
          transform: translateX(0) scale(0);
        }

        &::before {
          top: 50%;
          transform: rotate(45deg) translate(0, -50%);
        }

        &::after {
          bottom: 50%;
          transform: rotate(-45deg) translate(0, 50%);
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .header {
    &__burger-icon {
      height: 24px;
      width: 24px;
    }
  }
}

@media screen and (max-width: 414px) {
  .header {
    &__logo {
      max-width: 150px;
    }
  }
}
