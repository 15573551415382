@font-face {
  font-family: "Roboto";
  src: local("Roboto"), local("Roboto-Regular"), url("./roboto/Roboto.woff2") format("woff2"),
  url("./roboto/Roboto.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Medium"), local("Roboto-Medium"), url("./roboto/Robotomedium.woff2") format("woff2"),
  url("./roboto/Robotomedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./roboto/Robotobold.woff2") format("woff2"),
  url("./roboto/Robotobold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarckScript-Regular";
  src: local("MarckScript-Regular"), local("MarckScript-Regular"),
  url("./marckscript/MarckScript-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
