$colorBackgroundPopup: rgba(0, 0, 0, 0.8);

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $colorBackgroundPopup;
  opacity: 0;
  transition: all 0.6s;
  z-index: -1;
  overflow-y: auto;
  &__opened {
    opacity: 1;
    z-index: 99;
  }
}
