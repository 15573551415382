@import "../../styles/vars";

.aboutPhotoSession {
  width: 100%;

  &__container {
    max-width: 100%;
    margin: 40px auto 0;
  }

  &__textContainer {
    display: flex;
  }

  &__title {
    font-size: 27px;
    font-weight: normal;
    color: $TextColorBrownDark;
    margin: 0 0 50px;
  }
}

@media screen and (max-width: 768px) {
  .aboutPhotoSession {
    &__container {
      padding: 0;
    }

    &__title {
      font-size: 25px;
      padding: 0 30px;
      margin: 0;
    }
  }
}

@media screen and (max-width: 485px) {
  .aboutPhotoSession {
    &__title {
      font-size: 23px;
    }
  }
}

@media screen and (max-width: 374px) {
  .aboutPhotoSession {
    &__title {
      font-size: 20px;
    }
  }
}
