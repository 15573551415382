$colorBackground: rgb(231, 222, 195);
$colorDemarcationLine: #79553d;

.footer {
  background-color: $colorBackground;
  max-width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    position: relative;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(3, 390px);
    //grid-template-rows: 130px;

    &::before {
      content: "";
      border-right: solid 2px $colorDemarcationLine;
      position: absolute;
      left: 33%;
      height: 100%;
    }

    &::after {
      content: "";
      border-right: solid 2px $colorDemarcationLine;
      position: absolute;
      right: 33%;
      height: 100%;
    }

    &_edit {
      grid-template-columns: repeat(2, 390px);

      &::after {
        content: none;
      }

      &::before {
        left: 50%;
      }
    }
  }

  &__copyright {
    margin: auto auto auto 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 270px;
  }

  &__copyright-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 5px;

    &:nth-of-type(1) {
      margin-right: 5px;
    }

    &:nth-of-type(2) {
      margin-right: 0;
    }
  }

  &__copyright-link {
    text-decoration: none;
    color: #79553d;
    font-size: 14px;
    line-height: 20px;
    margin-left: 5px;
    display: block;
  }

  &__links-container {
    height: max-content;
    margin: 0 auto;
    width: max-content;
  }
}

@media screen and (max-width: 1170px) {
  .footer {
    width: 100%;

    &__links-container {
      margin: auto;
    }

    &__container {
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      margin: auto;

      &_edit {
        grid-template-columns: repeat(2, 390px);
        width: max-content;
      }
    }

    &__copyright {
      margin: auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    &__copyright-text {
      &:nth-of-type(1) {
        flex-basis: 100%;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .footer {
    &__copyright-text {
      font-size: 12px;
      &:nth-of-type(1) {
        flex-basis: 100%;
      }
      &_edit {
        font-size: 14px;
      }
    }
    &__copyright-link {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding-top: 15px;
    padding-bottom: 15px;

    &__container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr max-content;

      &::before {
        left: 50%;
        height: 70%;
      }

      &::after {
        display: none;
      }

      &_edit {
        grid-template-columns: repeat(1, 390px);
        width: max-content;

        &::before {
          content: none;
        }
      }
    }

    &__copyright {
      padding-left: 20px;
      padding-right: 20px;
      grid-row: 2/-1;
      grid-column: 1/-1;
      max-width: 100%;
      margin: 20px auto 0;

      &_edit {
        position: relative;
        padding-top: 15px;

        &::before {
          content: "";
          border-top: solid 2px $colorDemarcationLine;
          position: absolute;
          top: 0;
          height: 2px;
          width: 100vw;
        }
      }
    }

    &__copyright-text {
      &:nth-of-type(1) {
        margin-right: 5px;
        flex-basis: initial;
      }

      &:nth-of-type(2) {
        margin-right: 5px;
        margin-bottom: 5px;
      }

      &:nth-of-type(3) {
        flex-basis: initial;
        margin-bottom: 5px;
        margin-right: 5px;
      }

      &_edit {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .footer {
    &__container {
      grid-template-columns: 1fr;
      grid-template-rows: max-content 1fr max-content;

      &::before {
        display: none;
      }

      &_edit {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
        width: 100%;
      }
    }
    &__copyright-text {
      &:nth-of-type(3) {
        margin-right: 0;
      }
    }
    &__links-container {
      margin: 15px auto;
    }

    &__copyright {
      position: relative;
      grid-row: 3/-1;
      grid-column: 1/-1;
      margin: 0 auto;
      padding-top: 15px;
      width: 80%;

      &::before {
        content: "";
        border-top: solid 2px $colorDemarcationLine;
        position: absolute;
        top: 0;
        height: 2px;
        width: 100%;
      }

      &_edit {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .footer {
    &__copyright {
      padding-right: 0;
      padding-left: 0;
      width: 85%;
    }
  }
}
