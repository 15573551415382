.investmentOnMainPage {
  width: 100%;
  height: max-content;
  background-color: rgba(255, 212, 178, 0.25);
  padding: 30px 0;

  &__title {
    font-family: "MarckScript-Regular", sans-serif;
    font-size: 40px;
    font-weight: normal;
    color: #79553d;
  }

  &__description {
    font-weight: normal;
    font-size: 18px;
    padding: 10px 30px 30px;
  }

  &__containerPackages {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 0 40px;
    gap: 15px;
  }

  &__package {
    display: flex;
    flex-direction: column;
  }

  &__linkPackage {
    display: inline-block;
    position: relative;
    text-decoration: none;
    text-align: center;
  }

  &__overlayOnImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    z-index: 12;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &__imagePackage {
    display: block;
    width: 300px;
    height: 200px;
    box-shadow: 1px -1px 20px -7px rgba(121, 85, 61, 1);
  }

  &__textPackage {
    text-align: center;
    margin: 10px 0;
    color: rgba(121, 85, 61, 1);
  }
}

.investmentOnMainPage__linkPackage:hover .investmentOnMainPage__overlayOnImage {
  opacity: 1;
  box-shadow: 1px -1px 20px -7px rgba(121, 85, 61, 1);
}

@media screen and (max-width: 460px) {
  .investmentOnMainPage {
    &__description {
      font-size: 16px;
    }
  }
}
