@import "../../styles/vars";
$colorLineBottomLinks: #c69b6b;

.prices {
  width: 100%;
  min-height: 661px;
  margin: 0 auto;
  &__importantMessage {
    margin: 20px 0;
    padding: 0 20px;
    line-height: 1.35;
  }

  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 50px auto;
  }

  &__packets {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin: 0 auto;
    padding: 0 40px;
    list-style: none;
    justify-items: center;

  }

  &__title {
    position: relative;
    font-size: 28px;
    grid-column: 1/-1;
    margin: 30px auto;
    color: $TextColorBrownDark;
    font-weight: normal;
    padding: 0 20px;
    transition: width 0.4s;
  }

  &__titleUnderLine {
    margin: 0 auto;
    transform: translateX(50%);
    width: 50%;
    height: 1px;
    background: rgba(121, 85, 61, 0.2);
    background-image: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(255, 255, 255, 0.7)), color-stop(0.15, rgba(121, 85, 61, 0.2)), color-stop(0.85, rgba(121, 85, 61, 0.2)), color-stop(0.99, rgba(255, 255, 255, 1)));
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(121, 85, 61, 0.2) 15%, rgba(121, 85, 61, 0.2) 85%, rgba(255, 255, 255, 1) 99%);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(121, 85, 61, 0.2) 15%, rgba(121, 85, 61, 0.2) 85%, rgba(255, 255, 255, 1) 99%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(121, 85, 61, 0.2) 15%, rgba(121, 85, 61, 0.2) 85%, rgba(255, 255, 255, 1) 99%);
    background: linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(121, 85, 61, 0.2) 15%, rgba(121, 85, 61, 0.2) 85%, rgba(255, 255, 255, 1) 99%);
  }

  &_caption {
    text-align: left;
    max-width: 950px;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  .prices {
    flex-direction: column;

    &__title {
      font-size: 26px;
    }

    &__packets {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 776px) {
  .prices {
    &__packets {
      margin: 30px auto;
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 567px) {
  .prices {
    &__title {
      font-size: 23px;
    }

    &__titleUnderLine {
      width: 100%;
    }

    &__packets {
      padding: 0 20px;
      grid-template-columns: 1fr;
    }
  }
}
