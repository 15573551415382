.packetDetails {
  max-width: 950px;
  width: 100%;

  &__linkBack {
    display: block;
    color: rgba(137, 134, 134, 0.8);
    text-decoration: none;
    width: max-content;
  }

  &__containerImage {
    max-height: max-content;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__image {
    max-height: 400px;
    box-shadow: 0 10px 40px rgba(0, 1, 0, 0.4);
    border-radius: 10px;
  }

  &__aboutPacket {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  &__title {
    width: 100%;
    flex-shrink: 1;
    font-size: 35px;
    margin-bottom: 50px;
    font-weight: 400;
    color: #79553d;
  }

  &__price {
    font-size: 35px;
    margin-bottom: 20px;
    color: #ff9696;
  }

  &__getFromPhotosession {
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__getFromPhotosessionList {
    white-space: pre-wrap;
    font-weight: normal;
    padding-left: 20px;
    margin-bottom: 30px;

    &_item {
      margin-top: 5px;
    }
  }

  &__containerLinkScroll {
    text-align: left;
  }

  &__linkScroll {
    margin-left: 10px;
    text-decoration: underline;
    color: #79553d;

    &:hover {
      cursor: pointer;
    }
  }

  &__category {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 16px;

    &_span {
      font-weight: normal;
      padding-left: 5px;
    }
  }

  &__containerDescription {
    color: #606060;
    margin-bottom: 30px;
  }

  &__titleDescription {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #79553d;
  }

  &__containerListDescription {
    text-align: left;
    margin-bottom: 20px;
  }

  &__descriptionItem {
    margin-top: 5px;
    clear: left;
    list-style: none;
  }

  &__itemListContainer {
    list-style-type: circle;
  }

  &__itemList {
    padding-top: 5px;
    list-style-position: inside;

    &:first-child {
      list-style-type: none;
      padding-top: 0;
    }
  }

  &__alreadyInBasket {
    font-size: 15px;
    margin-top: 5px;
    color: darkolivegreen;
  }
}

@media screen and (max-width: 1024px) {
  .packetDetails {
    padding: 0 20px;

    &__containerImage {
      flex-direction: column;
    }

    &__title {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__image {
      max-height: 400px;
      object-fit: cover;
      width: 100%;
    }

    &__aboutPacket {
      margin: 30px auto 0;
      padding: 0 50px;
    }

    &__linkBack {
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .packetDetails {
    &__linkBack {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 560px) {
  .packetDetails {
    padding: 0 10px;

    &__price {
      font-size: 25px;
    }

    &__aboutPacket {
      margin: 50px auto 0;
      padding: 0 20px;
    }

    &__aboutPacket {
      align-items: center;
    }

    &__title {
      font-size: 26px;
    }

    &__getFromPhotosession {
      display: flex;
      flex-direction: column;
      align-items: center;

      &_title {
        display: block;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .packetDetails {
    &__aboutPacket {
      margin: 20px auto 0;
      padding: 0 20px;
    }
  }
}
