.copyLink {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  color: #79553d;
  &:hover {
    cursor: pointer;
  }
}
