$colorBackgroundOverlay: rgba(0, 0, 0, 0.4);

.photo {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;

  &__containerBtnUpDown {
    z-index: 10;
    position: absolute;
    content: "";
    top: 20px;
    left: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
    height: max-content;
    align-self: center;
  }

  &__btnUpDown {
    border: none;
    padding: 10px;
    background-size: 20px;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: #ffffff4c;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__btnUp {
    background-image: url("../../../../images/arrow-up.svg");
  }

  &__btnDown {
    background-image: url("../../../../images/arrow-down.svg");
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBackgroundOverlay;
    opacity: 0;
    border-radius: 5px;
    transition: all 0.4s;
  }

  &:hover &__overlay {
    opacity: 1;
    cursor: pointer;
  }

  &:hover &__image {
    transform: scale(1.1);
  }

  &__overlayIcon {
    background: url("../../../../images/magnifier-icon(white).webp") center no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__image {
    position: relative;
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    transition: all 0.4s;
    transform: scale(1);
  }

  &__delete {
    position: absolute;
    content: "";
    border: 0;
    background-image: url("../../../../images/deletePhoto-button.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: initial;
    width: 30px;
    height: 30px;
    outline: none;
    top: 20px;
    right: 20px;
    z-index: 100;

    &:hover {
      cursor: pointer;
      background-color: #ffffff4c;
      border-radius: 5px;
    }
  }

  &__container {
    &:first-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    &:nth-of-type(2) {
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-of-type(3) {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 4;
      grid-column-end: 6;
    }

    &:nth-of-type(4) {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 5;
    }

    &:nth-of-type(5) {
      grid-row-start: 3;
      grid-row-end: 5;
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
}

@media screen and (max-width: 1024px) {
  .photo {
    &__container {
      &:first-of-type {
        grid-column: 1/2;
        grid-row: 1/3;
      }

      &:nth-of-type(2) {
        grid-column: 2/3;
        grid-row: 1/2;
      }

      &:nth-of-type(3) {
        grid-column: 3/-1;
        grid-row: 1/2;
      }

      &:nth-of-type(4) {
        grid-column: 1/2;
        grid-row: 3/4;
      }

      &:nth-of-type(5) {
        grid-column: 2/-1;
        grid-row: 2/4;
      }

      &:nth-of-type(6) {
        grid-column: 1/3;
        grid-row: 4/5;
      }

      &:nth-of-type(7) {
        grid-column: 3/4;
        grid-row: 4/6;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .photo {
    &__container {
      &:first-of-type {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      &:nth-of-type(2) {
        grid-column: 2/-1;
        grid-row: 1/2;
      }

      &:nth-of-type(3) {
        grid-column: 1/-1;
        grid-row: 2/3;
      }

      &:nth-of-type(4) {
        grid-column: 1/2;
        grid-row: 3/4;
      }

      &:nth-of-type(5) {
        grid-column: 2/3;
        grid-row: 3/4;
      }

      &:nth-of-type(6) {
        grid-column: 1/3;
        grid-row: 4/5;
      }

      &:nth-of-type(7) {
        grid-column: 1/2;
        grid-row: 5/6;
      }

      &:nth-of-type(8) {
        grid-column: 2/3;
        grid-row: 5/6;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .photo {
    &__container {
      &:first-of-type {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      &:nth-of-type(2) {
        grid-column: 1/2;
        grid-row: 2/3;
      }

      &:nth-of-type(3) {
        grid-column: 1/2;
        grid-row: 3/4;
      }

      &:nth-of-type(4) {
        grid-column: 1/2;
        grid-row: 4/5;
      }

      &:nth-of-type(5) {
        grid-column: 1/2;
        grid-row: 5/6;
      }

      &:nth-of-type(6) {
        grid-column: 1/2;
        grid-row: 6/7;
      }
    }
  }
}
