.form {
  &__admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__order {
    max-height: 500px;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    padding: 0;
    border: none;
  }

  &__userProfile {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .form {
    &__order {
      display: block;
    }
  }
}
