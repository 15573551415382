$colorBackgroundOverlay: rgba(0, 0, 0, 0.4);

.photos {
  display: grid;
  grid-auto-rows: 300px;
  padding: 0;
  list-style: none;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 40px;
  isolation: isolate;

  &__gallery {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 40px;
    display: grid;
    justify-content: center;
    grid-template-rows: repeat(3, 300px);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 300px;
    grid-gap: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .photos {
    grid-template-columns: repeat(3, 1fr);

    &__gallery {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .photos {
    grid-template-columns: repeat(2, 1fr);

    &__gallery {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 567px) {
  .photos {
    grid-template-columns: 1fr;

    &__gallery {
      grid-gap: 20px;
    }
  }
}

@media screen and (max-width: 414px) {
  .photos {
    &__gallery {
      padding: 0 10px;
      grid-gap: 10px;
    }
  }
}
