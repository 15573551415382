@import "./src/styles/vars.scss";

.button {
  width: 100%;
  padding: 10px 15px;
  transition: all 0.3s ease;
  color: $ButtonTextColorWhite;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;

  &__icon {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }

  &_disabled {
    box-shadow: none;
    cursor: default;
  }

  &__ping {
    width: max-content;
    background-color: $ButtonColorPing;
    padding: 10px 35px;
    font-size: 15px;
    height: 37px;

    &:hover {
      background-color: $ButtonHoverColor;
    }

    &_disabled {
      background-color: $ButtonDisabledColor;

      &:hover {
        background-color: $ButtonDisabledColor;
      }
    }

    &_green {
      background-color: $TextColorGreen-1;

      &:hover {
        background-color: $TextColorGreen-2;
      }
    }

    &_buttonFormContact {
      margin-top: 30px;
      min-width: 240px;
    }

    &_buttonBasket {
      align-self: flex-end;
    }

    &_buttonOrder {
      align-self: flex-end;
      min-width: 150px;
    }

    &_buttonPriceDescription {
      justify-self: center;
      align-self: center;
      min-width: min-content;
    }

    &_buttonOverlayPacketOrder {
      opacity: 0;
    }

    &_buttonSubmitOrder {
      grid-column: 2/-1;
      grid-row: 4/-1;
      justify-self: flex-end;
      margin-top: 20px;
    }

    &_buttonUser {
      padding: 5px 15px;
      height: max-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &_authButton {
      width: 100%;
      margin: 20px auto 20px;
      height: 40px;
      justify-content: center;
    }

    &_contactMeButton {
      margin: 20px auto 0;
    }

    &_editorButton {
      margin: 30px auto 0;
    }
  }

  &__brown {
    color: $TextColorBrownDark;
    background-color: $BackgroundColorBrown-1;
    justify-content: center;

    &:hover {
      background-color: $BackgroundColorBrown-2;
    }

    &_disabled {
      background-color: $ButtonDisabledColor;

      &:hover {
        background-color: $ButtonDisabledColor;
      }
    }

    &_adminFormButton {
      width: 100%;
      margin-top: 30px;
    }
  }

  &__simply {
    justify-content: center;
    color: $TextColorBlack;

    &:hover {
      background-color: transparent;
    }
  }

  &__transparent {
    background-color: transparent;
    width: max-content;
    color: $TextColorBrownDark;
    margin-top: 20px;
    border: 1px solid $BorderColorBrown-1;

    &:hover {
      background-color: transparent;
      color: $TextColorBrown-2;
      border: 1px solid $BorderColorBrown-3;
    }

    &_disabled {
      display: none;
    }
  }

  &_hide {
    display: none;
  }
}

@media screen and (max-width: 390px) {
  .button {
    &__ping {
      &_buttonBasket {
        align-self: center;
        font-size: 14px;
      }

      &_buttonSubmitOrder {
        grid-column: 1/2;
        grid-row: 5/6;
        justify-self: flex-end;
        padding: 10px 15px;
      }
    }
  }
}
