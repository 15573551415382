.noteImportantPrices {
  width: 100%;
  background-color: rgba(255, 212, 178, 0.25);

  &__container {
    max-width: 950px;
    padding: 50px 40px;
    width: 100%;
    margin: 0 auto;
  }

  &__title {
    font-weight: normal;
    margin-bottom: 30px;
  }

  &__containerNotes {
    text-align: left;
  }

  &__note {
    margin-bottom: 5px;
  }
}
