.dataUser {
  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto 0;

    &:last-of-type {
      margin: 40px auto 0;
    }
  }

  &__cardTitle {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 22px;
  }
}

@media screen and (max-width: 568px) {
  .dataUser {
    &__cardTitle {
      font-size: 18px;
    }
  }
}
