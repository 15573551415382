.contacts {
  margin: 50px auto;
  transition: opacity 0.4s ease-in;
  &__title {
    margin: 0 0 15px;
    color: #79553d;
    font-weight: normal;
    font-size: 33px;

    &_span {
      display: block;
      margin-top: 10px;
      font-size: 18px;
    }
  }

  &__logoAuth {
    margin-bottom: 30px;
    max-width: 283px;
    width: 80%;
  }
}

@media screen and (max-width: 414px) {
  .contacts {
    &__title {
      font-size: 28px;
    }

    &__logoAuth {
      margin-bottom: 30px;
    }
  }
}
