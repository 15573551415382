@import "../../styles/vars";

.blog {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  &__title {
    padding: 50px 0 30px;
    margin: 0;
    font-family: "MarckScript-Regular", sans-serif;
    font-size: 40px;
    font-weight: normal;
    color: $TextColorBrownDark;
  }
}
