.packets {
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 40px;
  margin: 50px auto;
  padding: 0 40px;
  list-style: none;
}
