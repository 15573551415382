$colorLinksHover: #79553d;
$colorText: black;
$colorTextOnThePageContacts: #c69b6b;

.myContacts {
  min-width: 250px;

  &__container {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  &__item-container {
    display: flex;
    align-items: center;
    margin: 0 auto 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin: 0 15px 0 0;

    &_place {
      background: url("../../images/place-icon(black).svg") no-repeat center;
      background-size: contain;
    }

    &_email {
      background: url("../../images/email-icon(black).svg") no-repeat center;
      background-size: contain;
    }

    &_phone {
      background: url("../../images/phone-icon(black).svg") no-repeat center;
      background-size: contain;
    }

    &_instagram {
      background: url("../../images/instagram.svg") no-repeat center;
      background-size: contain;
      width: 20px;
      height: 20px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $colorText;
  }

  &__link {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $colorText;
    text-decoration: none;
    transition: all 0.4s;

    &:hover {
      color: $colorLinksHover;
    }
  }

  &__edit {
    font-size: 18px;
    line-height: 24px;
    color: $colorTextOnThePageContacts;
  }
}

@media screen and (max-width: 850px) {
  .myContacts {
    &__item-container {
      margin: 0 auto 10px;
    }

    &__text {
      font-size: 12px;
      line-height: 14px;
    }

    &__link {
      font-size: 12px;
      line-height: 14px;
    }

    &__edit {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 567px) {
  .myContacts {
    &__item-container {
      margin: 0 auto 15px;
    }

    &__text {
      font-size: 14px;
      line-height: 16px;
    }

    &__link {
      font-size: 14px;
      line-height: 16px;
    }

    &__edit {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 414px) {
  .myContacts {
    &__edit {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
