$colorLinks: #c69b6b;
$colorInitDots: #bbbb;
$colorActiveDots: #717171;
$colorText: white;
$colorBackgroundLink: #ff9696;
$colorBackgroundLinkHover: rgba(255, 150, 150, 0.6);
$colorBackgroundArrow: rgba(255, 255, 255, 0.1);
$colorBackgroundArrowHover: rgba(255, 255, 255, 0.2);
$colorArrow: rgba(255, 255, 255, 0.5);
$colorArrowHover: rgba(255, 255, 255, 0.9);

.mySwiper {
  height: 100%;
}

.swiperBtn {
  width: 40px;
  height: 100px;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50px);
  border: none;
  background-color: $colorBackgroundArrow;
  font-size: 28px;
  color: $colorArrow;
  border-radius: 20px;
  transition: all 0.3s;

  &:hover {
    background-color: $colorBackgroundArrowHover;
    color: $colorArrowHover;
  }

  &_prev {
    left: 100px;
  }

  &_next {
    right: 100px;
  }
}

.slideShow {
  position: relative;
  height: 640px;
  width: 100%;
  box-sizing: border-box;

  &__welcomeContainer {
    display: flex;
    position: absolute;
    max-width: 1440px;
    padding: 0 50px;
    width: 100%;
    bottom: 30px;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    z-index: 10;
  }

  &__aboutMeContainer {
    text-align: left;
  }

  &__welcomeTitle {
    display: block;
    width: max-content;
    font-size: 45px;
    font-weight: 700;
    margin: 0;
    background: -webkit-linear-gradient(#ff9696, #ffe89e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 1s;

    &_span {
      font-size: 35px;
      font-weight: 700;
      background: -webkit-linear-gradient(#ff9696, #ffe89e);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__welcomeDescription {
    margin: 10px 0 0;
    width: max-content;
    max-width: 100%;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-style: normal;
    transition: all 1s 0.4s;
    color: $colorText;
  }

  &__welcomeLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 15px 0 0;
    width: 120px;
    height: 40px;
    font-size: 15px;
    font-weight: 400;
    border: none;
    border-radius: 15px;
    background-color: $colorBackgroundLink;
    color: $colorText;
    transition: transform 1s 0.6s, opacity 1s 0.6s, background 0.6s;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: $colorBackgroundLinkHover;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__linkAboutNewbornContainer {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    width: 100%;
    text-align: end;
  }

  &__linkAboutNewborn {
    height: max-content;
    animation: animationLinkAboutNewborn 2.5s infinite;
    display: inline-block;
    font-size: 16px;
    color: white;
    background-color: #ff9696;
    padding: 10px;
    border-radius: 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .slideShow {
    &__linkAboutNewborn {
      max-width: min-content;
      text-align: center;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .slideShow {
    height: 650px;

    &__welcomeContainer {
      padding: 0 20px;
    }

    &__aboutMeContainer {
    }

    &__welcomeTitle {
      font-size: 40px;
      font-weight: 700;

      &_span {
        font-size: 30px;
      }
    }

    &__welcomeDescription {
      font-size: 15px;
    }
  }
  .swiperBtn {
    border-radius: 0;
    background-color: transparent;

    &_prev {
      left: 20px;
    }

    &_next {
      right: 20px;
    }
  }
}

@media screen and (max-width: 568px) {
  .slideShow {
    height: 550px;

    &__welcomeTitle {
      font-size: 30px;

      &_span {
        font-size: 20px;
      }
    }

    &__welcomeDescription {
      margin: 0;
      font-size: 14px;
    }

    &__welcomeLink {
      width: 100px;
      height: 35px;
      font-size: 13px;
      border-radius: 10px;
    }

    &__linkAboutNewborn {
      font-size: 12px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 500px) {
  .slideShow {
    &__welcomeDescription {
      font-size: 12px;
      line-height: 15px;
    }

  }

}

@media screen and (max-width: 474px) {
  .slideShow {
    height: 400px;

    &__welcomeTitle {
      font-size: 25px;

      &_span {
        font-size: 18px;
      }
    }

    &__welcomeDescription {
      width: 80%;
    }

    &__welcomeLink {
      width: 90px;
    }

    &__linkAboutNewborn {
      font-size: 11px;
    }
  }
  .swiperBtn {
    &_prev {
      left: 0;
    }

    &_next {
      right: 0;
    }
  }
}

@media screen and (max-width: 443px) {
  .slideShow {
    height: 480px;

    &__welcomeContainer {
      flex-direction: column;
    }

    &__linkAboutNewbornContainer {
      justify-content: flex-start;
    }

    &__linkAboutNewborn {
      max-width: max-content;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 413px) {
  .slideShow {
    &__aboutMeContainer {
      display: block;
    }

    &__welcomeDescription {
      margin: 5px 0 0;
    }

    &__welcomeLink {
      margin: 8px auto 0 0;
    }
  }
}

@keyframes animationLinkAboutNewborn {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
