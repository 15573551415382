@import "../../../../styles/vars";

.tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__title {
    color: rgb(121, 85, 61);
    font-weight: normal;
    margin: 20px auto 0;
    font-size: 25px;
    padding: 0 20px;
  }

  &__radio {
    display: none;
  }

  &__content {
    order: 99; // Put the tabs last
    flex-grow: 1;
    width: 100%;
    display: none;
    padding-top: 30px;
  }

  &__radio:checked + &__labelRadio + &__content {
    display: block;
  }

  &__blockText {
    width: 100%;
  }

  &__noteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 0;
    position: relative;
  }

  &__noteButton {
    border: none;
    background: url("../../../../../src/images/icon-note-editor-text.png") no-repeat center;
    background-size: contain;
    width: 48px;
    height: 48px;

    &:hover {
      cursor: pointer;
      border-radius: 50%;
      background-color: $BackgroundColorGray-2;
    }
  }

  &__note {
    width: 300px;
    padding: 10px;
    background-color: $BackgroundColorWhite;
    position: absolute;
    content: "";
    display: none;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    border-radius: 10px;
    transform: translate(-100%, -100%);
    left: 0;
    top: 50%;
    z-index: 100;

    &_open {
      display: grid;
    }
  }

  &__coverContainerNote {
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;

    &_open {
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.05);
      display: block;
    }
  }

  &__textContainer {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 0;

    &:first-of-type {
      margin: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .tabs {
    &__content {
      position: relative;
      z-index: 1;
    }

    &__title {
      font-size: 23px;
    }
  }
}
