@import "./src/styles/vars";

$colorLinks: #ffffff;
$colorLinksHover: #c69b6b;
$colorLinksActive: #c69b6b;
$colorLinksSelector: #000000;
$colorSelectContainer: #ffffff;
$boxShadowSelectContainer: rgba(0, 0, 0, 0.2);
$colorBackgroundBluerBurgerMenu: rgba(105, 101, 84, 0.42);
$colorBackgroundContainerBurgerMenu: rgb(255, 252, 236);
$colorBackgroundSubContainerBurgerMenu: rgba(233, 228, 209, 0.56);
$colorLinksInBurgerMenu: #696554;

.navigation {
  margin: 21px 50px auto auto;
  display: flex;
  max-width: 100%;

  &__list {
    &_links {
      display: flex;
      list-style: none;
      max-width: 100%;
      padding: 0;
      justify-content: space-between;
      margin: auto 0 auto auto;
      min-width: 676px;
      width: 100%;
      align-items: center;
    }
  }

  &__containerItem {
    &:first-of-type {
    }
  }

  &__containerLinks {
    display: flex;
    justify-content: center;
  }

  &__container-link {
    align-self: flex-start;
    position: relative;
    min-width: max-content;
    display: flex;
    justify-content: flex-end;
    margin-left: 40px;
    height: max-content;

    &:nth-of-type(1) {
      margin-left: 0;
    }

    &:nth-of-type(4) {
      margin-right: auto;
    }
  }

  &__link {
    position: relative;
    font-size: 16px;
    color: $colorLinks;
    text-decoration: none;
    transition: all 0.4s;
    border-bottom: 10px solid transparent;
    margin-top: 5px;

    &:hover {
      color: $colorLinksHover;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 50%;
      height: 2px;
      background-color: $colorLinksActive;
      width: 0;
      transform: translateX(-50%);
      transition: width 0.4s;
      z-index: 1;
    }

    &_active {
      &::after {
        width: 100%;
      }
    }
  }

  &__view-lists-links {
    position: relative;

    &:hover &_active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &__link-arrow {
    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: -15px;
      height: 10px;
      width: 10px;
      background: url("../../../../../images/arrow-icon.svg") no-repeat center;
      background-size: contain;
      border-bottom: 15px solid transparent;
      opacity: 1;
    }

    &:hover {
      cursor: default;

      &::before {
        background: url("../../../../../images/arrow-icon-hover.svg") no-repeat center;
        color: $colorLinksHover;
      }
    }
  }

  &__link-logo {
    height: 18px;
    width: 20px;
    position: relative;

    & span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: $colorIconBurgerMenu;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 50%;
      background-color: $colorIconBurgerMenu;
      transition: all 0.2s ease-in;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 50%;
      background-color: $colorIconBurgerMenu;
      transition: all 0.2s ease-in;
    }

    &:hover {
      & span {
        width: 50%;
        background-color: $colorLinksHover;
      }

      &:before {
        width: 100%;
        background-color: $colorLinksHover;
      }

      &:after {
        height: 2px;
        width: 100%;
        background-color: $colorLinksHover;
      }
    }

    &_active {
      & span {
        background-color: $colorLinksHover;
      }

      &:before {
        background-color: $colorLinksHover;
      }

      &:after {
        background-color: $colorLinksHover;
      }
    }
  }

  &__subLinks-container {
    position: absolute;
    left: 0;
    top: 30%;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: $colorSelectContainer;
    width: 200px;
    margin-top: 18px;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 $boxShadowSelectContainer;
    transform: translateY(30px);
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  &__sublink {
    position: relative;
    text-decoration: none;
    width: fit-content;
    margin: 10px;
    color: $colorLinksSelector;
    transition: 0.8s transform;

    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 50%;
      height: 2px;
      background-color: $colorLinksActive;
      width: 0;
      transform: translateX(-50%);
      transition: width 0.4s;
      z-index: 5;
    }

    &_active {
      &::after {
        width: 100%;
      }
    }

    //&:last-of-type {
    //  max-width: min-content;
    //}
    &:hover {
      color: $colorLinksHover;
      transform: translateX(5px);
    }
  }

  &__basket {
    width: 27px;
    height: 27px;
    transition: transform 0.4s;
    position: relative;
    background: url("../../../../../images/basket.png") no-repeat center;
    background-size: cover;

    &:hover {
      cursor: pointer;
    }

    &_notEmpty {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(20%, 50%);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #ff9696;
      text-align: center;
      font-size: 11px;
      align-items: center;
      justify-content: center;
    }
  }

  &__basketTooltip {
    opacity: 0;
    visibility: hidden;
    padding: 10px 15px;
    width: max-content;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(200%);
    transition: transform 0.4s;
    background-color: white;
    border-radius: 10px;
    color: #797878;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &__tooltip {
    transition: transform 0.4s;

    &:hover &_active {
      opacity: 1;
      visibility: visible;
      transform: translateY(130%);
    }
  }

  &__menuContact {
    display: block;
    visibility: visible;
    height: 16px;
    width: 20px;
    position: relative;

    &:hover {
      cursor: pointer;

      & span {
        transform: translate(0);
      }

      &::before {
        background-color: $colorLinksHover;
        transform: translate(-50%, 0);
      }

      &::after {
        transform: translate(-50%, 0);
        background-color: $colorLinksHover;
      }
    }

    & span {
      position: absolute;
      top: 50%;
      transition: transform 0.3s ease-in;
      right: 0;
      height: 10%;
      width: 75%;
      background-color: $colorIconBurgerMenu;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 10%;
      width: 50%;
      background-color: $colorIconBurgerMenu;
      transition: transform 0.3s ease-in;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      height: 10%;
      width: 50%;
      background-color: $colorIconBurgerMenu;
      transition: transform 0.3s ease-in;
    }
  }

  &__containerBtnUser {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    transition: all 3s ease-in-out;

    width: 120px;
  }

  &__textUserBtn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__adminPanel {
    text-decoration: none;
    color: #ff9696;
    padding-top: 5px;
  }

  //&__buttonChangeLanguage {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  border: none;
  //  outline: none;
  //  background-color: transparent;
  //  padding: 5px;
  //  margin-right: 5px;
  //  color: white;
  //  cursor: pointer;
  //  transition: 0.3s ease;
  //
  //  &:last-of-type {
  //    margin-right: 0;
  //  }
  //
  //  &_active {
  //    background-color: rgba(255, 255, 255, 0.2);
  //    border-radius: 5px;
  //  }
  //}
}

@media screen and (max-width: 1279px) {
  .navigation {
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $colorBackgroundBluerBurgerMenu;
    margin: 0;
    transition: opacity 0.4s ease-in;

    //&__buttonChangeLanguage {
    //  background-color: rgba(87, 87, 87, 0.1);
    //  border-radius: 5px;
    //  margin-right: 10px;
    //  color: black;
    //
    //  &_active {
    //    background-color: rgba(49, 46, 46, 0.2);
    //  }
    //}

    &__burgerMenu_active {
      opacity: 1;
      visibility: visible;
    }

    &__list {
      &_links {
        display: flex;
        flex-direction: column;
        justify-content: initial;
        height: 100%;
        width: 30%;
        padding: 100px 0 0 0;
        transform: translateX(50px);
        transition: all 0.7s ease-in;
        min-width: initial;
        margin: auto auto auto 0;
      }
    }

    &__link-arrow {
      width: 100%;
      text-align: left;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        height: 0;
        width: 0;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 10px solid $colorLinksInBurgerMenu;
      }

      &:hover {
        &::before {
          background: none;
        }
      }
    }

    &__link-arrow.subLinks__active {
      &::before {
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: none;
        border-bottom: 10px solid $colorLinksInBurgerMenu;
      }
    }

    &__subLinks-container {
      display: none;
      background-color: $colorBackgroundSubContainerBurgerMenu;
      position: static;
      text-align: left;
      width: 100%;
      transform: translate(0, 0);
      opacity: 1;
      border-radius: initial;
      transition: all 0.7s ease-in;
      box-shadow: none;
      margin: 0;
      z-index: 1;
      visibility: visible;
    }

    &__subLinks-container_active {
      display: flex;
    }

    &__sublink {
      color: $colorLinksInBurgerMenu;
    }

    &__link-logo {
      & span {
        background-color: $colorLinksInBurgerMenu;
      }

      &:before {
        background-color: $colorLinksInBurgerMenu;
      }

      &:after {
        background-color: $colorLinksInBurgerMenu;
      }
    }

    &__basketTooltip {
      transform: translate(100%, 200%);
      background-color: transparent;
    }

    &__tooltip {
      &:hover &_active {
        transform: translate(100%, 130%);
      }
    }

    &__container-link {
      justify-content: flex-start;
      margin-left: 0;
      flex-wrap: wrap;
      padding: 0 0 0 40px;
      margin-bottom: 20px;
      min-width: 100%;

      &:last-of-type {
        margin-bottom: 0;
        margin-top: 80px;
      }

      &:nth-of-type(1) {
        margin-left: 0;
      }

      &:nth-of-type(4) {
        margin-left: 0;
      }

      &:nth-of-type(5) {
        margin-left: 0;
      }

      &:nth-of-type(6) {
        margin-left: 0;
      }
    }

    &__link {
      font-size: 18px;
      color: $colorLinksInBurgerMenu;
      margin-right: 40px;
    }

    &__burgerMenu_active &__list_links {
      transform: translateX(0);
      background-color: $colorBackgroundContainerBurgerMenu;
      width: 50%;
    }

    &__basket {
      background: url("../../../../../images/basket-burgerMenu.png") no-repeat center;
      background-size: cover;
    }
  }
}

@media screen and (max-width: 568px) {
  .navigation {
    &__list_links {
      width: 40%;
    }

    &__link-arrow.subLinks__active {
      &::before {
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: none;
        border-bottom: 10px solid $colorLinksInBurgerMenu;
      }
    }

    &__burgerMenu_active &__list_links {
      width: 60%;
    }
  }
}

@media screen and (max-width: 414px) {
  .navigation {
    &__list_links {
      width: 50%;
    }

    &__container-link {
      padding: 0 0 0 20px;
    }

    &__burgerMenu_active &__list_links {
      width: 70%;
    }
  }
}

@media screen and (max-width: 320px) {
  .navigation {
    &__list_links {
      width: 60%;
    }

    &__burgerMenu_active &__list_links {
      width: 80%;
    }
  }
}
