.socialLinks {
  margin: 10px 0;
  width: 100%;
  overflow: hidden;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__item-social-networks {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 15px 0 0;
    transition: all 0.4s;

    &:hover {
      opacity: 0.6;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  &__item-social-networksImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 850px) {
  .socialLinks {
    margin: 20px 0 0;
  }
}
