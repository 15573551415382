@import "../../styles/vars";

.editorContainer {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.editor {
  background-color: $BackgroundColorWhite;
  padding: 0 5px;
}
