.orderData {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: rgba(137, 134, 134, 0.1);
  width: 100%;

  &__packet {
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px solid rgba(73, 73, 73, 0.4);

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__containerPacket {
    list-style: none;
  }

  &__describePacket {
    display: flex;
    justify-content: space-between;
    color: #898686ff;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &_span {
      margin-left: 10px;
      color: rgba(66, 66, 66, 1);
      width: max-content;
    }
  }
}

@media screen and (max-width: 568px) {
  .orderData {
    &__describePacket {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 414px) {
  .orderData {
    &__describePacket {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;

      &_span {
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}
