#fountainG {
  position: relative;
  width: 54px;
  height: 7px;
  margin: auto;
}

.fountainG {
  position: absolute;
  top: 0;
  background-color: rgb(0, 0, 0);
  width: 7px;
  height: 7px;
  animation-name: bounce_fountainG;
  -o-animation-name: bounce_fountainG;
  -ms-animation-name: bounce_fountainG;
  -webkit-animation-name: bounce_fountainG;
  -moz-animation-name: bounce_fountainG;
  animation-duration: 0.715s;
  -o-animation-duration: 0.715s;
  -ms-animation-duration: 0.715s;
  -webkit-animation-duration: 0.715s;
  -moz-animation-duration: 0.715s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  transform: scale(.3);
  -o-transform: scale(.3);
  -ms-transform: scale(.3);
  -webkit-transform: scale(.3);
  -moz-transform: scale(.3);
  border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

#fountainG_1 {
  left: 0;
  animation-delay: 0.286s;
  -o-animation-delay: 0.286s;
  -ms-animation-delay: 0.286s;
  -webkit-animation-delay: 0.286s;
  -moz-animation-delay: 0.286s;
}

#fountainG_2 {
  left: 7px;
  animation-delay: 0.3525s;
  -o-animation-delay: 0.3525s;
  -ms-animation-delay: 0.3525s;
  -webkit-animation-delay: 0.3525s;
  -moz-animation-delay: 0.3525s;
}

#fountainG_3 {
  left: 13px;
  animation-delay: 0.429s;
  -o-animation-delay: 0.429s;
  -ms-animation-delay: 0.429s;
  -webkit-animation-delay: 0.429s;
  -moz-animation-delay: 0.429s;
}

#fountainG_4 {
  left: 20px;
  animation-delay: 0.4955s;
  -o-animation-delay: 0.4955s;
  -ms-animation-delay: 0.4955s;
  -webkit-animation-delay: 0.4955s;
  -moz-animation-delay: 0.4955s;
}

#fountainG_5 {
  left: 27px;
  animation-delay: 0.572s;
  -o-animation-delay: 0.572s;
  -ms-animation-delay: 0.572s;
  -webkit-animation-delay: 0.572s;
  -moz-animation-delay: 0.572s;
}

#fountainG_6 {
  left: 34px;
  animation-delay: 0.6385s;
  -o-animation-delay: 0.6385s;
  -ms-animation-delay: 0.6385s;
  -webkit-animation-delay: 0.6385s;
  -moz-animation-delay: 0.6385s;
}

#fountainG_7 {
  left: 40px;
  animation-delay: 0.715s;
  -o-animation-delay: 0.715s;
  -ms-animation-delay: 0.715s;
  -webkit-animation-delay: 0.715s;
  -moz-animation-delay: 0.715s;
}

#fountainG_8 {
  left: 47px;
  animation-delay: 0.7815s;
  -o-animation-delay: 0.7815s;
  -ms-animation-delay: 0.7815s;
  -webkit-animation-delay: 0.7815s;
  -moz-animation-delay: 0.7815s;
}


@keyframes bounce_fountainG {
  0% {
    transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    transform: scale(.3);
    background-color: rgb(255, 255, 255);
  }
}

@-o-keyframes bounce_fountainG {
  0% {
    -o-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -o-transform: scale(.3);
    background-color: rgb(255, 255, 255);
  }
}

@-ms-keyframes bounce_fountainG {
  0% {
    -ms-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -ms-transform: scale(.3);
    background-color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes bounce_fountainG {
  0% {
    -webkit-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -webkit-transform: scale(.3);
    background-color: rgb(255, 255, 255);
  }
}

@-moz-keyframes bounce_fountainG {
  0% {
    -moz-transform: scale(1);
    background-color: rgb(0, 0, 0);
  }

  100% {
    -moz-transform: scale(.3);
    background-color: rgb(255, 255, 255);
  }
}

