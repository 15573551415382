$colorHeaderBackground: #89868666;
$colorRowBackground: #89868623;

.table {
  width: 100%;
  margin-top: 30px;
  table-layout: fixed;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 5px;

  &__header-container {
    background-color: $colorHeaderBackground;
    border: none;

  }

  &__header {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid gray;

    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:first-of-type {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }

  &__row-container {
    width: 100%;

    &:nth-child(2n) {
      background-color: $colorRowBackground;
    }
  }

  &__row {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid gray;

    &:last-of-type {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:first-of-type {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
}

@media screen and (max-width: 567px) {
  .table {
    &__header {
      font-size: 13px;
    }

    &__row {
      font-size: 13px;
    }
  }
}
