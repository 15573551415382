.formOrder {
  max-width: 1000px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;

  &__formTitle {
    font-size: 40px;
    font-weight: normal;
    margin: 30px 0 0;
    width: max-content;
  }

  &__formDescription {
    font-size: 15px;
    color: rgba(255, 0, 0, 0.5);

    &_error {
      color: rgba(255, 0, 0, 0.5);
      background-color: rgba(255, 150, 150, 0.4);
      padding: 10px;
      border-radius: 5px;
    }
  }

  &__containerHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px;
    width: 50%;
  }

  &__containerOrder {
    width: 100%;
  }
}

@media screen and (max-width: 1060px) {
  .formOrder {
    margin: 50px 30px;
  }
}

@media screen and (max-width: 568px) {
  .formOrder {
    &__formTitle {
      font-size: 25px;
      margin: 30px auto 0;
    }

    &__containerHeader {
      width: 100%;
    }
  }
}


