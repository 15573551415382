@import "./src/styles/vars.scss";

.scrollUp {
  position: fixed;
  bottom: 15%;
  right: 5%;
  opacity: 0;
  scale: 0.5;
  transform: translateY(50px);
  transition: all 0.5s;
  width: 36px;
  height: 36px;
  z-index: 50;

  &_active {
    opacity: 1;
    scale: 1;
    transform: translateY(0);
  }

  &__button {
    border: none;
    width: 100%;
    height: 100%;
    background-color: $ButtonColorPing;
    border-radius: 50%;
    transition: background-color 0.3s;

    &:hover {
      cursor: pointer;
      background-color: $ButtonHoverColor;
    }
  }

  &__buttonIcon {
    display: block;
    border-style: none;
    margin: 0;
    padding: 0;
  }
}
