.blogArticleDetails {
  display: flex;
  flex-direction: column;
  padding: 30px;
  &__title {
    width: 100%;
    font-size: 25px;
    color: #79553d;
    margin: 30px 0 15px;
  }
  &__typeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #79553d;
    font-weight: normal;
    line-height: 20px;
    margin: 0 auto;
    font-size: 16px;
    text-align: right;
    max-width: 1024px;
    width: 100%;
  }
  &__typePhotoSession {
    font-size: 20px;
    font-weight: normal;
    color: #79553d;
    margin: 15px auto;
  }

  &__titleUnderLine {
    transform: translateX(50%);
    width: 50%;
    height: 1px;
    background: rgba(121, 85, 61, 0.2);
    background-image: -webkit-gradient(
      linear,
      0 0,
      100% 0,
      from(rgba(255, 255, 255, 0.7)),
      color-stop(0.15, rgba(121, 85, 61, 0.2)),
      color-stop(0.85, rgba(121, 85, 61, 0.2)),
      color-stop(0.99, rgba(255, 255, 255, 1))
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(121, 85, 61, 0.2) 15%,
      rgba(121, 85, 61, 0.2) 85%,
      rgba(255, 255, 255, 1) 99%
    );
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(121, 85, 61, 0.2) 15%,
      rgba(121, 85, 61, 0.2) 85%,
      rgba(255, 255, 255, 1) 99%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(121, 85, 61, 0.2) 15%,
      rgba(121, 85, 61, 0.2) 85%,
      rgba(255, 255, 255, 1) 99%
    );
    background: linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(121, 85, 61, 0.2) 15%,
      rgba(121, 85, 61, 0.2) 85%,
      rgba(255, 255, 255, 1) 99%
    );
  }
  &__imagesContainer {
    align-self: center;
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
  }
  &__image {
    display: block;
   width: 100%;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__linkBackContainer {
    max-width: 1024px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  &__descriptionContainer {
    max-width: 1024px;
    width: 100%;
    margin: auto;
    padding: 30px 0;
    text-align: left;
  }
  &__descriptionContainer p {
    margin-bottom: 10px; /* Измените значение по вашему усмотрению */
    color: #85736a;
    line-height: 25px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .blogArticleDetails {
    &__titleUnderLine {
      transform: translateX(0);
      width: 100%;
    }
    &__descriptionContainer {
      padding: 15px 0;
    }
  }
}
@media screen and (max-width: 476px) {
  .blogArticleDetails {
    padding: 30px 20px;
    &__title {
      font-size: 22px;
    }
    &__typePhotoSession {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 414px) {
  .blogArticleDetails {
    &__title {
      font-size: 19px;
      line-height: 25px;
    }
  }
}

