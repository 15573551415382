@import "../../styles/vars";

.field {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 15px 0 0;

    &_checkbox {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &_nameOrder {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    &_emailOrder {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    &_telOrder {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    &_textOrder {
      grid-column: 2/-1;
      grid-row: 1/4;

    }
  }

  &__item {
    margin-top: 5px;
    max-width: 100%;
    width: 100%;
    padding: 10px 0 10px 10px;
    border: 1px solid rgba(128, 128, 128, 0.4);
    border-radius: 5px;

    &_checkbox {
      width: max-content;
      margin: 0 0 0 10px;
    }

    &_error {
      border: 1px solid red;
    }

    &_nameOrder {
      box-shadow: 0 1px 6px 0 #00000066;
    }

    &_emailOrder {
      box-shadow: 0 1px 6px 0 #00000066;
    }

    &_telOrder {
      box-shadow: 0 1px 6px 0 #00000066;
    }

    &_textOrder {
      resize: none;
      height: 100%;
      box-shadow: 0 1px 6px 0 #00000066;

      &::placeholder {
        color: rgba(137, 134, 134, 0.6);
      }
    }

    &_userProfile {
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(128, 128, 128, 0.4);
      outline: none;
    }
  }

  &__label {
    color: $TextColorBrownDark;
  }

  &__error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .field__item_password {
    font-family: Verdana, Geneva, sans-serif;
    letter-spacing: -.75px;
  }
}


//@media screen and (max-width: 390px) {
//  .field {
//    &__container {
//      &_nameOrder {
//        grid-column: 1/2;
//        grid-row: 1/2;
//      }
//
//      &_emailOrder {
//        grid-column: 1/2;
//        grid-row: 2/3;
//      }
//      &_telOrder {
//        grid-column: 1/2;
//        grid-row: 3/4;
//      }
//      &_textOrder {
//        grid-column: 1/2;
//        grid-row: 4/5;
//      }
//    }
//    &__label {
//      font-size: 12px;
//    }
//    &__error {
//      font-size: 10px;
//    }
//  }
//}
