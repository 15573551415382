.background-image {
  background: url("../../images/background-image.webp") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 767px) {
  .background-image {
    height: 320px;
  }
}
