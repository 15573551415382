.order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
  min-width: 350px;

  &_checkout {
    width: max-content;
  }

  &__title {
    background-color: rgba(80, 78, 78, 0.6);
    width: 100%;
    text-align: left;
    padding: 15px 30px 15px 30px;
    color: white;
    display: flex;
    justify-content: space-between;
  }

  &__packets {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: rgba(137, 134, 134, 0.1);
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .order {
    width: 100%;

    &__title {
      font-size: 15px;
      padding: 15px;
    }
  }
}

@media screen and (max-width: 414px) {
  .order {
    min-width: 260px;

    &__title {
      display: flex;
      flex-direction: column;
      color: rgba(51, 50, 50, 0.99);

      &_span {
        margin-top: 5px;
        color: white;
      }
    }
  }
}
