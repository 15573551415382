.basket {
  &__container {
    max-width: 1024px;
    min-height: 400px;
    margin: 50px auto;
    padding: 0 40px;
  }

  &__notPackets {
    padding-top: 100px;
  }

  &__wrapperTable {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__containerLinkRegister {
    background-color: rgba(102, 99, 99, 0.1);
    padding: 15px;
    border-radius: 5px;
  }

  &__linkRegister {
    font-size: 18px;
    color: #ff9696;
    display: block;
    margin-top: 15px;
  }
}

@media screen and (max-width: 768px) {
  .basket {
    &__container {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 470px) {
  .basket {
    &__container {
      margin: 30px auto;
      padding: 0 10px;
    }

    &__wrapperTable {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
