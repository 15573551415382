$backgroundColor: white;
$backgroundColorButton: #ff9696;
$colorTextButton: white;
$colorBoxShadowButton: #666363;

.messageToTheUser {
  width: 100%;

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
  }

  &__container {
    margin: 30px 0;
  }

  &__image {
    width: 50px;
    height: 50px;
  }

  &__title {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
  }

  &__button {
    width: 150px;
    height: 40px;
    border-radius: 15px;
    border: none;
    margin-top: 40px;
    background-color: $backgroundColorButton;
    color: $colorTextButton;
    box-shadow: 0 1px 6px 0 $colorBoxShadowButton;
    transition: all 0.4s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 768px) {
  .messageToTheUser {
    &__container {
      padding: 0 15px;
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin-top: 15px;
    }
  }
}
