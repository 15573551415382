@import "../../styles/vars";

.comparePhotos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  &__chooseTypePhoto {
    display: flex;
    list-style: none;
  }

  &__typePhoto {
    width: 150px;
    padding: 10px 40px;
    background: rgba(255, 231, 214, 0.2);
    border: none;
    color: $TextColorBrownDark;

    &:hover {
      cursor: pointer;
    }

    &_active {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        right: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(255, 231, 214);
        z-index: 10;
      }
    }
  }

  &__typePhotoOverlay {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-bottom: 0;
    z-index: 1;

    &_active {
      border: 1px solid $TextColorBrownDark;
      background-color: rgb(255, 231, 214);
      z-index: 2;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
      }

      &:last-of-type {
        border-top-right-radius: 5px;
        border-top-left-radius: 0;
      }
    }
  }

  &__containerSlide {
    background-color: rgb(255, 231, 214);
    border: 1px solid $TextColorBrownDark;
    padding: 5px;

  }

  &__container {
    position: relative;
    max-width: 1100px;
    margin: 0 40px;
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: initial;
    }
  }

  &__photo {
    border-radius: 5px;
    box-shadow: gray 0 0 70px;
  }

  &__title {
    font-size: 20px;
    font-weight: normal;
    padding: 0 15px 20px 15px;
    margin: 0 30px;
  }

  &__overlay {
    position: absolute;
    content: "";
    top: 50%;
    width: 70px;
    background-color: rgba(121, 120, 120, 0.3);
    color: #d9d9d9;
    padding: 15px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: 0.4s linear opacity;

    &_before {
      left: 60px;
      z-index: 2;
    }

    &_after {
      right: 60px;
      z-index: 0;
    }
  }

  &__container:hover &__overlay {
    opacity: 1;
  }

  &__buttonContainer {
    z-index: 10;
    place-content: center;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
    margin: 0;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.7);

    &:hover {
      cursor: col-resize;
    }
  }

  &__buttonIcon {
    width: 100%;
    height: 100%;
    display: inline-block;
    object-position: center;
    object-fit: none;
  }

  &__line {
    position: relative;
    height: 100%;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 100%;
      border: 1px solid rgba(255, 255, 255, 0.7);
      cursor: col-resize;
    }
  }
}

@media screen and (max-width: 1024px) {
  .comparePhotos {
    &__container {
      margin: 0 30px;
    }

    &__title {
      margin: 0 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .comparePhotos {
    &__container {
      margin: 0 10px;
    }

    &__title {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 568px) {
  .comparePhotos {
    &__title {
      font-size: 16px;
    }

    &__chooseTypePhoto {
      display: flex;
      justify-content: space-between;
    }

    &__typePhotoOverlay {
      width: 100%;
    }

    &__typePhoto {
      width: 100%;
    }
  }
}
